import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <p>Copyright@HIKKOSHI-MITSUMORI all Right Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer