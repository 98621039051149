import React, { useState } from "react";
import Scheduler from "react-mui-scheduler";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Prefecturelist } from "../values/value";
import { DateModal } from "./modal";
import { set_movingDate } from "../store/movingDate";

// import axios from "axios";
// import { useEffect } from "react";
// import { CONTRACT_ENDPOINT } from "../endpoint";
import { AlertDialogSlide } from "./notificationModal";
import Header from "./header";

// import { Button, Modal, Box } from "@mui/material";

const MovingDate = () => {
  const [openModal, setOpenModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [calendarEvents, setCaledarEvents] = useState([]);
  const dispatch = useDispatch();
  const { movingDate } = useSelector(state => state);
  const furniture = useSelector(state => state.furniture)
  const address = useSelector(state => state.address);
  const [dayTime, setDaytime] = useState([]);
  const [selectedHours, setSelectedHours] = useState([]);
  const [state] = useState({
    options: {
      transitionMode: "slide", // or fade
      startWeekOn: "mon",     // or sun
      defaultMode: "month",    // or week | day | timeline
      minWidth: 540,
      maxWidth: 540,
      minHeight: 540,
      maxHeight: 540,
    },
    alertProps: {
      open: false
    },
    toolbarProps: {
      showDatePicker: true
    }
  })

  const handleCellClick = (event, row, day) => {
    const year = day.date.getFullYear()
    const month = (day.date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const date = day.date.getDate().toString().padStart(2, '0');
    const currentDay = `${year}-${month}-${date}`;
    setSelectedHours([]);
    dayTime.map(item => {
      const dateData = item.split('T');
      const newDay = dateData[0];
      const newHour = dateData[1].split(':')[0];
      if (newDay === currentDay) {
        setSelectedHours((prev) => [...prev, newHour]);
      }
    })
    const nowDate = new Date();
    if (day.date > nowDate)
      setOpenModal(true);
    else setNotificationModal(true);
    const newDate = {
      ...movingDate,
      year,
      month,
      date,
    };
    dispatch(set_movingDate(newDate));
  }

  const handleEventClick = (event, item) => {
  }

  const handleEventsChange = (item) => {
  }

  const handleAlertCloseButtonClicked = (item) => {
  }

  const currentPrefecture = Prefecturelist.find((item) => (item.name === address.currentPrefecture))
  const newPrefecture = Prefecturelist.find((item) => (item.name === address.newPrefecture));

  return (
    <section>
      <Header />
      <div className="percent-bar">
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData1} step={"10"} readOnly />
          <div className="number">{furniture.percentData1}%</div>
        </div>
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData2} step={"10"} readOnly />
          <div className="number">{furniture.percentData2}%</div>
        </div>
        {furniture.percentData2 && furniture.percentData2 === 100 && (
          <div className="visit-price">2トン以上のお荷物は訪問見積もりになります</div>
        )}
      </div>
      <div className="current-new-address">
        <div className="current">
          <p className="current-label">現住所</p>
          <div className="current-address-confir">{currentPrefecture.name + address.currentCity}</div>
        </div>
        <div className="movement-arrow">
          <img src="./image/arrow.png" alt="arrow" />
        </div>
        <div className="new">
          <p className="new-label">転居先</p>
          <div className="new-address-confir">{newPrefecture.name + address.currentCity}</div>
        </div>
      </div>
      <div className="step1"><img src="./image/step2.png" alt="step2" /></div>
      {<Scheduler
          locale="Ja"
          events={calendarEvents}
          legacyStyle={false}
          options={state.options}
          alertProps={state.alertProps}
          toolbarProps={state.toolbarProps}
          onEventsChange={handleEventsChange}
          onCellClick={handleCellClick}
          onTaskClick={handleEventClick}
          onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
        />
      }
      <div className="furniture-btn">
        <Link to={"/confirm"} className="btn-shape fur-btn-previous">戻る</Link>
      </div>
      <div><DateModal open={openModal && selectedHours.length < 2} onClose={() => setOpenModal(false)} hours={selectedHours} /></div>
      <AlertDialogSlide show={notificationModal} handleClose={() => setNotificationModal(false)} message={"正確な日時を選択してください。"} />
    </section>
  )
}

export default MovingDate;


