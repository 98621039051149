import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  totalFee: 0,
  basicFee: 0,
  changedFee: 0,
}

export const feeSlice = createSlice ({
  name: "fee",
  initialState: defaultState,
  reducers: {
    set_fee: (state, action) => {
      state.totalFee = action.payload;
    },
    set_changed_fee: (state, action) => {
      state.changedFee = action.payload;
    }
  }
})

export const { set_fee, set_changed_fee } = feeSlice.actions;
export const feeReducer = feeSlice.reducer