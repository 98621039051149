import React, { useEffect, useState } from "react";
import Prefecture from "./prefecture";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_address } from "../store/address";
import { delete_furniture } from "../store/furniturelist";
import axios from "axios";
import { DISTANCE_ENDPOINT, OPTION_ENDPOINT } from "../endpoint";
import { AlertDialogSlide } from "./notificationModal";
import Header from "./header";
import { set_changed_fee } from "../store/fee";

const Address = () => {
  let navigate = useNavigate();
  const [notificationModal, setNotificationModal] = useState(false);
  const routeChange = () => {
    navigate("/furniture");
  }
  const dispatch = useDispatch();
  const [addressData, setAddressData] = useState({
    currentPrefecture: '1',
    currentCity: '1',
    newPrefecture: '1',
    newCity: '1',
    distance: 0
  })

  const changeCurrentAddress = (data) => {
    const save_data = {
      currentPrefecture: data.prefecture,
      currentCity: data.city,
    };
    setAddressData({
      ...addressData,
      ...save_data
    });
  }

  const changeNewAddress = (data) => {          //add changed new address
    const save_data = {
      newPrefecture: data.prefecture,
      newCity: data.city,
    };
    setAddressData({
      ...addressData,
      ...save_data
    });
  }
  const handleSave = () => {
    dispatch(set_address(addressData)); // save store
  }

  const funCall = (e) => {
    axios.get(DISTANCE_ENDPOINT, { params: { from_area: addressData.currentCity, to_area: addressData.newCity } })
      .then(response => {
        const distance = (response.data.distance / 1000);
        setAddressData({ ...addressData, distance })
        dispatch(set_address({ ...addressData, distance }));
      })
      .catch(error => {
        console.log(error);
      })
    if (addressData.currentPrefecture === '1' || addressData.currentCity === '0' || addressData.newPrefecture === '1' || addressData.newCity === '0') {
      setNotificationModal(true);
    }
    else {
      handleSave();
      routeChange();
      dispatch(delete_furniture());
    }
  }

  useEffect(() => {
    try {
      const func = async () => {
        const res = await axios.get(OPTION_ENDPOINT)
        dispatch(set_changed_fee(res.data))
      }
      func();
    } catch (error) {
      console.log(error);
    }
  }, [])

  return (
    <section>
      <Header />
      <div className="main-bg">
        <div className="address">
          <Prefecture title={'現住所'} changeAddress={changeCurrentAddress} />
          <div className="car"><img src="./image/car.png" alt="car" /></div>
          <Prefecture title={'転居先'} changeAddress={changeNewAddress} />
        </div>
        <button onClick={funCall} className="address-btn">ご家財の内容を入力</button>
      </div>
      <AlertDialogSlide show={notificationModal} handleClose={() => setNotificationModal(false)} message={"現住所の都道府県を選択してください"} />
    </section>
  )
}

export default Address