export const Prefecturelist = [
  { value: "1", name: "都道府県を選択", state: "selected" },
  { value: "2", name: '北海道' },
  { value: "3", name: '岩手県' },
  { value: "4", name: '宮城県' },
  { value: "5", name: '秋田県' },
  { value: "6", name: '山形県' },
  { value: "7", name: '福島県' },
  { value: "8", name: '茨城県' },
  { value: "9", name: '栃木県' },
  { value: "10", name: '群馬県' },
  { value: "11", name: '埼玉県' },
  { value: "12", name: '千葉県' },
  { value: "13", name: '東京都' },
  { value: "14", name: '神奈川県' },
  { value: "15", name: '新潟県' },
  { value: "16", name: '富山県' },
  { value: "17", name: '石川県' },
  { value: "18", name: '福井県' },
  { value: "19", name: '山梨県' },
  { value: "20", name: '長野県' },
  { value: "21", name: '岐阜県' },
  { value: "22", name: '静岡県' },               
  { value: "23", name: '愛知県' },
  { value: "24", name: '三重県' },
  { value: "25", name: '滋賀県' },
  { value: "26", name: '京都府' },
  { value: "27", name: '大阪府' },
  { value: "28", name: '兵庫県' },
  { value: "29", name: '奈良県' },
  { value: "30", name: '和歌山県' },
  { value: "31", name: '鳥取県' },
  { value: "32", name: '島根県' },
  { value: "33", name: '岡山県' },
  { value: "34", name: '広島県' },
  { value: "35", name: '山口県' },
  { value: "36", name: '徳島県' },
  { value: "37", name: '香川県' },
  { value: "38", name: '愛媛県' },
  { value: "39", name: '高知県' },
  { value: "40", name: '福岡県' },
  { value: "41", name: '佐賀県' },
  { value: "42", name: '長崎県' },
  { value: "43", name: '熊本県' },
  { value: "44", name: '大分県' },
  { value: "45", name: '宮崎県' },
  { value: "46", name: '鹿児島県' },
  { value: "47", name: '沖縄県' },
];

export const furnitureDatabase = [
  { id: "1", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "2", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "3", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "4", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "5", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "6", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "7", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "8", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "9", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "10", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "11", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "12", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "13", image: 'furniture-items.png', name: 'パソコン', type: "appliances", percentage: 10 },
  { id: "14", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "15", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "16", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "17", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "18", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "19", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "20", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "21", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "22", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "23", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "24", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "25", image: 'furniture-items.png', name: 'sdfsdfsdf', type: "woodenFurniture", percentage: 10 },
  { id: "26", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "27", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "28", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "29", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "30", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "31", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "32", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "33", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "34", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "35", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "36", image: 'furniture-items.png', name: 'av-pc', type: "avPc", percentage: 10 },
  { id: "37", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "livingware", percentage: 10 },
  { id: "38", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "livingware", percentage: 10 },
  { id: "39", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "livingware", percentage: 10 },
  { id: "40", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "livingware", percentage: 10 },
  { id: "41", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "42", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "43", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "44", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "45", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "46", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "47", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
  { id: "48", image: 'furniture-items.png', name: 'snflknsdfnsd', type: "other", percentage: 10 },
];