import React, { useRef, useState } from "react";
import { Prefecturelist } from "../values/value";
import axios from "axios";
import { CITY_NAME_ENDPOINT } from "../endpoint";

const Prefecture = (props) => {

  const prefRef = useRef();
  const cityRef = useRef();
  const [cityNames, setCityNames] = useState([]);
  const handleChangePrefecture = () => {
    cityRef.current.value = 0;
    axios.get(CITY_NAME_ENDPOINT, { params: { prefecture: prefRef.current.value } })
      .then(response => {
        const keys = Object.keys(response.data);
        let arr = [];
        keys.forEach(key => {
          //
          arr.push({
            key: key,
            value: response.data[key],
          });
        });
        setCityNames(arr);
      })
      .catch(error => {
        console.log(error);
      });
    props.changeAddress({ prefecture: prefRef.current.value, city: cityRef.current.value });
  }
  const handleChangeCity = () => {
    props.changeAddress({ prefecture: prefRef.current.value, city: cityRef.current.value });
  }
  return (
    <div className="current-address">
      <p className="current-address-title">{props.title}</p>
      <div className="current-address-input">
        <select name="prefecture" id="prefecture" onChange={handleChangePrefecture} ref={prefRef}>
          {Prefecturelist.map((item, index) => (
            <option value={item.name} key={index}>{item.name}</option>
          ))}
        </select>
        <select name="city" id="city" onChange={handleChangeCity} ref={cityRef}>
          <option key={0} value={0}>市区町村を選択</option>
          {cityNames.map((item, index) => <option key={index} value={item.id}>{item.value}</option>)}
        </select>
      </div>
    </div>
  )
}

export default Prefecture