import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_contactUs } from "../store/contact";


const Contact = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/input");
  }
  const movingDate = useSelector(state => state.movingDate);
  const dispatch = useDispatch();
  // const contactUs = useSelector(state => state.contactUs);
  const address = useSelector(state => state.address);
  const furniture = useSelector(state => state.furniture);
  const fee = useSelector(state => state.fee);
  const reconfirm = useSelector(state => state.reconfirm);
  const [name, setName] = useState();
  const [furiName, setFuriName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [currentAddress, setCurrentAddress] = useState();
  const [newAddress, setNewAddress] = useState();
  const [errors, setErrors] = useState({});

  const formValidation = (event) => {
    event.preventDefault();
    // Perform validation
    const errors = {};
    if (!name) {
      errors.name = '氏名(姓)を入力してください。';
    }
    if (!furiName) {
      errors.furiName = '氏名(かな)を入力してください。';
    }
    if (!phone) {
      errors.phone = '電話番号を入力してください。';
    }
    if (!email) {
      errors.email = 'メールアドレスを入力してください。';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }
    if (!currentAddress) {
      errors.currentAddress = '現住所を入力してください。';
    }
    if (!newAddress) {
      errors.newAddress = '転居先を入力してください。';
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const contactData = {
        name: name,
        furiName: furiName,
        phone: phone,
        email: email,
        currentAddress: currentAddress,
        newAddress: newAddress,
      }
      dispatch(set_contactUs(contactData));
      routeChange();
    }
  }

  return (
    <section className="contact-us">
      <div className="contact">
        <div className="bar"></div>
        <p>国内のお引越お申し込み</p>
        <div className="state-display">
          <div className="customer-info state-btn action">お客さま情報のご入力</div>
          <div className="input-confirm state-btn">入力内容のご確認</div>
          <div className="application-complete state-btn">お申し込み完了</div>
        </div>
        <div className="inq_form">
          <div className="name">
            <div className="name_ming">
              <p>お名前</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setName(e.target.value)} value={name} />
              {errors.name && <div>{errors.name}</div>}
            </div>
          </div>
          <div className="name">
            <div className="name_ming">
              <p>ふりがな</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setFuriName(e.target.value)} value={furiName} />
              {errors.furiName && <div>{errors.furiName}</div>}
            </div>
          </div>
          <div className="name">
            <div className="name_ming">
              <p>電話番号</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setPhone(e.target.value)} value={phone} />
              {errors.phone && <div>{errors.phone}</div>}
            </div>
          </div>
          <div className="name">
            <div className="name_ming">
              <p>メールアドレス</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setEmail(e.target.value)} value={email} />
              {errors.email && <div>{errors.email}</div>}
            </div>
          </div>
          <div className="name">
            <div className="name_ming">
              <p>積み込み地の住所</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setCurrentAddress(e.target.value)} value={currentAddress} />
              {errors.currentAddress && <div>{errors.currentAddress}</div>}
            </div>
          </div>
          <div className="name">
            <div className="name_ming">
              <p>降し地（ご新居）の住所</p>
              <div className="essential">必須</div>
            </div>
            <div className="nameform">
              <input type="text" size="65" onChange={(e) => setNewAddress(e.target.value)} value={newAddress} />
              {errors.newAddress && <div>{errors.newAddress}</div>}
            </div>
          </div>
        </div>
        <div className="other-confirm">
          <table>
            <tr>
              <td>引越概算距離</td>
              <td>
                <p>{`${reconfirm.ttlDistance}km`}</p>
                <p>※営業所拠点からの距離算出となります。</p>
              </td>
            </tr>
            <tr>
              <td>ご家財搬出予定日</td>
              <td>{`${movingDate.year}年 ${movingDate.month}月 ${movingDate.date}日 ${movingDate.time < 12 ? "AM" : "PM"}`}</td>
            </tr>
            <tr>
              <td>ご家財搬入予定日</td>
              <td>仮申込み後、弊社オペレーターよりご連絡致します。</td>
            </tr>
            <tr>
              <td>お引越料金（概算/税込）</td>
              <td>{furniture.percentData2 < 100 ? `${fee.totalFee}円` : '訪問見積もり'}</td>
            </tr>
          </table>
        </div>
        <button onClick={formValidation} className="next-confirm">確認画面へ</button>
      </div>
    </section>
  )
}

export default Contact