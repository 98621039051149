import React, { useEffect, useState } from "react";
import FurnitureType from "./furnitureType";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { all_furnitures, set_furniture, set_percentData1, set_percentData2 } from "../store/furniturelist";
import { useNavigate } from "react-router-dom";
import { Prefecturelist } from "../values/value";
import { AlertDialogSlide } from "./notificationModal";
import axios from "axios";
import { FURNITURES_ENDPOINT } from "../endpoint";
import Header from "./header";
import Cardboard from "./cardboard";


const Furniture = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/confirm");
  }
  const [notificationModal, setNotificationModal] = useState(false);
  const [excessModal, setExcessModal] = useState(false);
  const { furniture } = useSelector(state => state);
  console.log("furnituresdfsd", furniture)
  const address = useSelector(state => state.address);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FURNITURES_ENDPOINT);
        dispatch(all_furnitures(response.data));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const changeFurniture = (id, num) => {
    const newFurniture = { furnitureId: id, furnitureNumber: num };
    const oldFurnitures = furniture.furnitureData.filter(item => item.furnitureId !== id);

    if (parseInt(num) > 0) {
      const data = [
        ...oldFurnitures,
        newFurniture
      ];
      dispatch(set_furniture(data));
    }
    else {
      const data = [
        ...oldFurnitures,
      ];
      dispatch(set_furniture(data));
    }

    let sumFurniture1 = 0;
    let sumFurniture2 = 0;
    // furniture.allFurnitures.map((item) => {
    //   const result = furniture.furnitureData.find((furniture) => furniture.furnitureId !== id && furniture.furnitureId === item.id);
    //   if (result !== undefined && Object.keys(result).length !== 0) {
    //     if (sumFurniture1 + parseFloat(item.percentage) * parseFloat(result.furnitureNumber) <= 100) {
    //       sumFurniture1 += parseFloat(item.percentage) * parseFloat(result.furnitureNumber);

    //     } else {
    //       sumFurniture2 += parseFloat(item.percentage) * parseFloat(result.furnitureNumber);
    //     }

    //   }
    // });
    furniture.allFurnitures.map((item) => {
      const result = furniture.furnitureData.find((furniture) => furniture.furnitureId !== id && furniture.furnitureId === item.id);
      if (result !== undefined && Object.keys(result).length !== 0) {
        if (sumFurniture1 + parseFloat(item.percentage) * parseFloat(result.furnitureNumber) <= 100) {
          sumFurniture1 += parseFloat(item.percentage) * parseFloat(result.furnitureNumber);
        } else {
          if (sumFurniture2 + sumFurniture1 + parseFloat(item.percentage) * parseFloat(result.furnitureNumber) - 100 < 100) {
            sumFurniture2 += sumFurniture1 + parseFloat(item.percentage) * parseFloat(result.furnitureNumber) - 100;
            sumFurniture1 = 100;
          } else {
            sumFurniture1 = 100;
            sumFurniture2 = 100;
          }
        }
      }
    });

    const result = furniture.allFurnitures.find((furniture) => furniture.id === id);

    // if (sumFurniture1 + parseFloat(result.percentage) * parseFloat(num) <= 100) {
    //   sumFurniture1 += parseFloat(result.percentage) * parseFloat(num);
    // } else {
    //   sumFurniture2 += parseFloat(result.percentage) * parseFloat(num);
    // }

    if (sumFurniture1 + parseFloat(result.percentage) * parseFloat(num) <= 100) {
      sumFurniture1 += parseFloat(result.percentage) * parseFloat(num);
    } else {
      if (sumFurniture2 + sumFurniture1 + parseFloat(result.percentage) * parseFloat(num) - 100 < 100) {
        sumFurniture2 += sumFurniture1 + parseFloat(result.percentage) * parseFloat(num) - 100;
        sumFurniture1 = 100;
      } else {
        sumFurniture1 = 100;
        sumFurniture2 = 100;
      }
    }
    dispatch(set_percentData1(sumFurniture1));
    dispatch(set_percentData2(sumFurniture2));
  }

  const funCall = () => {
    if (furniture.percentData1 === 0) {
      setNotificationModal(true);
    }
    // else if (furniture.percentData2 > 100) {
    //   setExcessModal(true);
    // }
    else routeChange();
  }

  const isStore = (id) => {
    const storeCheck = furniture.furnitureData.filter(item => item.furnitureId === id);
    return (storeCheck.length > 0);
  }

  const furnitureDataDelete = () => {
    dispatch(set_furniture([]));
    dispatch(set_percentData1("0"));
    dispatch(set_percentData2("0"));
    window.location.reload();
  }

  const itemValue = (index) => {
    const storeCheck = furniture.furnitureData.filter(item => item.furnitureId === index);
    if (storeCheck.length === 0) return 0;
    return parseInt(storeCheck[0].furnitureNumber);
  }

  const currentPrefecture = Prefecturelist.find((item) => (item.name === address.currentPrefecture));
  const newPrefecture = Prefecturelist.find((item) => (item.name === address.newPrefecture));

  return (
    <section>
      <Header />
      <div className="percent-bar">
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData1} step={"10"} readOnly />
          <div className="number">{furniture.percentData1}%</div>
        </div>
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData2} step={"10"} readOnly />
          <div className="number">{furniture.percentData2}%</div>
        </div>
        {furniture.percentData2 && furniture.percentData2 === 100 && (
          <div className="visit-price">2トン以上のお荷物は訪問見積りになります</div>
        )}
      </div>
      <div className="current-new-address">
        <div className="current">
          <p className="current-label">現住所</p>
          <div className="current-address-confir">{currentPrefecture.name + address.currentCity}</div>
        </div>
        <div className="movement-arrow">
          <img src="./image/arrow.png" alt="arrow" />
        </div>
        <div className="new">
          <p className="new-label">転居先</p>
          <div className="new-address-confir">{newPrefecture.name + address.newCity}</div>
        </div>
      </div>
      <div className="step1"><img src="./image/step1.png" alt="step1" /></div>
      <FurnitureType title="家電" type="appliances" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <FurnitureType title="家具" type="woodenFurniture" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <FurnitureType title="AV-PC" type="avPc" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <FurnitureType title="生活用品" type="livingware" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <FurnitureType title="その他" type="other" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <Cardboard title="ダンボール お運びするダンボールの想定数量をご入力下さい。" type="cardboard" changeFurniture={changeFurniture} itemValue={itemValue} isStore={isStore} />
      <div className="furniture-btn">
        <div className="btn-set">
          <Link to={"/"} className="btn-shape fur-btn-previous">戻る</Link>
          <button onClick={furnitureDataDelete} className="btn-shape fur-btn-reset">クリア</button>
        </div>
        <button onClick={funCall} className="fur-btn-next">確定</button>
        <AlertDialogSlide show={notificationModal} handleClose={() => setNotificationModal(false)} message={"家財が一つも選択されていません!"} />
        <AlertDialogSlide show={excessModal} handleClose={() => setExcessModal(false)} message={"車の積載量を超過しました。"} />
      </div>
    </section>
  )
}

export default Furniture