import React from "react"

const Header = () => {
  return (
    <div className="topimage">
      <div className="stage">
        <img src="./image/mainvisual-sp.jpg" alt="mainvisual" className="sp" />
        <img src="./image/mainvisual.jpg" alt="mainvisual" className="pc" />
      </div>
    </div>
  )
}

export default Header