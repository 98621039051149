import React, { useState } from "react";

const Items = (props) => {
  // const furnitureIdRef = useRef();
  // const furnitureNumberRef = useRef();
  const [selected, setSelected] = useState("")
  const handleChangeFurnitureNumber = (e) => {
    props.changeFurniture(e.target.value);
    e.target.value !== '0' ? setSelected("test") : setSelected("");    
  }

  return (
    <div className="furniture-items">
      <div className="items-img" >
        <img src={`https://hikkoshi-mitsumori.site/${props.image}`} alt="furniture-items" />
      </div>
      <div className={`items-label ${selected}  ${props.className}`}>{props.name}</div>
      <div className={`items-num ${selected}  ${props.className}`}>
        <select id="furniture-number" name="furniture-number" onChange={handleChangeFurnitureNumber} disabled={props.disabled}>
          <option value="0" selected={props.number == 0}>0台</option>
          <option value="1" selected={props.number == 1}>1台</option>
          <option value="2" selected={props.number == 2}>2台</option>
          <option value="3" selected={props.number == 3}>3台</option>
          <option value="4" selected={props.number == 4}>4台</option>
          <option value="5" selected={props.number == 5}>5台</option>
        </select>
      </div>
    </div>          
  )
}

const ConfirmItem = (props) => {
  const [selected, setSelected] = useState("")
  const handleChangeFurnitureNumber = (e) => {
    props.changeFurniture(e.target.value);
    e.target.value !== '0' ? setSelected("test") : setSelected("");    
  }

  return (
    <div className="furniture-items">
      <div className="items-img" >
        <img src={`https://hikkoshi-mitsumori.site/${props.image}`} alt="furniture-items" />
      </div>
      <div className={`items-label ${selected}  ${props.className}`}>{props.name}</div>
      <div className={`items-num ${selected}  ${props.className}`}>
        <select id="furniture-number" name="furniture-number" onChange={handleChangeFurnitureNumber} disabled={props.disabled}>
          <option value={props.number} selected>{props.number}台</option>
        </select>
      </div>
    </div>          
  )
}          

export { Items, ConfirmItem };