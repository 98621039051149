import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  year: "",
  month: "",
  date: "",
  time: "",
  
}

export const movingDateSlice = createSlice({
  name: "movingDate",
  initialState: defaultState,
  reducers: {
    set_movingDate: (state, action) => {
      state.year = action.payload.year;
      state.month = action.payload.month;
      state.date = action.payload.date;
      state.time = action.payload.time;
      
    }
  }
})

export const { set_movingDate } = movingDateSlice.actions;
export const movingDateReducer = movingDateSlice.reducer