import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_address } from "../store/address";
import { set_furniture, set_percentData1 } from "../store/furniturelist";
import { set_movingDate } from "../store/movingDate";
import { set_contactUs } from "../store/contact";
import { set_fee } from "../store/fee";


const Complete = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/");
  }
  const dispatch = useDispatch();
  const allReset = () => {
    dispatch(set_address({}));
    dispatch(set_furniture([]));
    dispatch(set_percentData1("0"));
    dispatch(set_movingDate([]));
    dispatch(set_contactUs([]));
    dispatch(set_fee("0"));
    routeChange();
  }
  return (
    <section className="contact-us">
      <div className="contact">
        <div className="bar"></div>
        <p>お申し込み完了</p>
        <div className="state-display">
          <div className="customer-info state-btn">お客さま情報のご入力</div>
          <div className="input-confirm state-btn">入力内容のご確認</div>
          <div className="application-complete state-btn action">お申し込み完了</div>
        </div>
        <div className="text">
          <p>お引越お申し込みありがとうございます。</p>
          <p>後ほど担当者よりご連絡させていただきます。<br />弊社担当者とお打合せ後、本契約となりますので、ご了承くださいませ。</p>
        </div>
        <button onClick={allReset} className="close-btn">閉じる</button>
      </div>
    </section>
  )
}

export default Complete