import React, { useState, useEffect } from "react";
import { Items } from "./items";
import { useSelector } from "react-redux";


const FurnitureType = (props) => {
  const furniture = useSelector(state => state.furniture);
  const [isOpen, setOpen] = useState(true);
  useEffect(() => {
    if (window.innerWidth > 768) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [])

  return (
    <div className="furniture-list">
      <div className="furniture-list-title" onClick={() => window.innerWidth <= 768 && setOpen(prev => !prev)}>{props.title}</div>
      <div className={`detail-list ${!isOpen ? "collapsed" : ""}`}>
        {furniture.allFurnitures && furniture.allFurnitures.filter((item) => (item.type === props.type)).map((item, index) => (
          <Items
            key={index}
            image={item.image}
            className={props.isStore(item.id) ? "test" : ""}
            name={item.name}
            number={props.itemValue(item.id)}
            changeFurniture={(num) => props.changeFurniture(item.id, num)} />
        ))}
      </div>
    </div>
  )
}

export default FurnitureType