import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  name: "",
  furiName: "",
  phone: "",
  email: "",
  currentAddress: "",
  newAddress: "",
}

export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: defaultState,
  reducers: {
    set_contactUs: (state, action) => {
      state.name = action.payload.name;
      state.furiName = action.payload.furiName;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.currentAddress = action.payload.currentAddress;
      state.newAddress = action.payload.newAddress;
    }
  }
})

export const {set_contactUs} = contactUsSlice.actions;
export const contactUsReducer = contactUsSlice.reducer;