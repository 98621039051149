import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Prefecturelist } from "../values/value";
import { set_fee } from "../store/fee";
import { useState } from "react";
import Header from "./header";
import axios from "axios";
import { DISTANCE_ENDPOINT, OPTION_ENDPOINT } from "../endpoint";
import { set_reconfirm } from "../store/reconfirm";

const Reconfirm = () => {
  const dispatch = useDispatch();
  const address = useSelector(state => state.address);
  const furniture = useSelector(state => state.furniture);
  const movingDate = useSelector(state => state.movingDate);
  const fee = useSelector(state => state.fee);
  const currentPrefecture = Prefecturelist.find((item) => (item.name === address.currentPrefecture));
  const newPrefecture = Prefecturelist.find((item) => (item.name === address.newPrefecture));
  const [basicFee, setBasicFee] = useState();
  const [distanceFee, setDistanceFee] = useState();
  const [totalFee, setTotalFee] = useState();
  const [totalDis, setTotalDis] = useState();
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/contact");
  }

  const [totalFeeData, setTotalFeeData] = useState({
    totalFee: 0,
    basicFee: 0,
    cardboardFee: 0,
  })

  const cardboard = furniture.furnitureData.filter(item => Number(item.furnitureNumber) > 50);
  let cardboardFee = 0;
  cardboard.map(item => {
    cardboardFee += (Number(item.furnitureNumber) - 50) * 300;
  })

  useEffect(() => {
    try {
      const func = async () => {
        const res = await axios.get(DISTANCE_ENDPOINT, { params: { from_area: "大野城市", to_area: address.currentCity } });
        const basicDistance = res.data.distance / 1000;
        const totalDistance = Math.round(basicDistance + address.distance);
        setTotalDis(totalDistance);
        let distance_Fee = Math.round((totalDistance > 20 ? Math.round(300 * (totalDistance - 20)) : 0) / 100) * 100;
        let discoundRate = fee.changedFee[0].discount_rate
        if (totalDistance > fee.changedFee[0].basic_distance) {
          let discound = 1 - discoundRate / 100;
          distance_Fee = distance_Fee * discound;
        }
        let changefee = fee.changedFee[0].basic_fee;
        let sec_truck_fee = fee.changedFee[0].ex_basic_fee;
        let basic = furniture.percentData2 ? sec_truck_fee : changefee;
        if (furniture.building > 1) {
          basic = basic + 1000
        }
        if (movingDate.time == 9) {
          basic = basic + 1000
        }
        setBasicFee(basic);
        setDistanceFee(Math.round(distance_Fee));
        let total_fee = Math.round(1.1 * (basic + distance_Fee + cardboardFee));
        setTotalFee(total_fee);
      }
      func();
    } catch (error) {
      console.log(error);
    }

  }, [])

  useEffect(() => {
    setTotalFeeData({
      ...totalFeeData,
      totalFee
    });
  }, [dispatch, furniture])

  const reconfirmBtnclick = () => {
    // dispatch(set_address({ ...address, distance }))
    dispatch(set_reconfirm(totalDis));
    furniture.percentData2 < 100 ? dispatch(set_fee(totalFee)) : dispatch(set_fee("訪問見積もり"));
    routeChange();
  }


  return (
    <section>
      <Header />
      <div className="step1 step3"><img src="./image/step3.png" alt="step3" /></div>
      <div className="box">
        <div className="content">
          <table className="table">
            <tbody>
              <tr>
                <td className="borderline fir-live" rowSpan={"2"}>お住まい</td>
                <td>現住所</td>
                <td rowSpan={"2"}><img src="./image/arrow1.png" alt="arrow" /></td>
                <td>転居先</td>
              </tr>
              <tr>
                <td>{currentPrefecture.name + address.currentCity}</td>
                <td>{newPrefecture.name + address.newCity}</td>
              </tr>
              <tr>
                <td className="borderline distance-title">距離</td>
                <td className="borderline" colSpan={"3"}>
                  <p>{`${totalDis || ''}km`}</p>
                  <p>※営業所拠点からの距離算出となります。</p>
                </td>
              </tr>
              <tr>
                <td className="borderline sec-live" rowSpan={"2"}>お住まい</td>
                <td>ご家財搬出予定日</td>
                <td rowSpan={"2"}><img src="./image/arrow1.png" alt="arrow" /></td>
                <td>ご家財搬入予定日</td>
              </tr>
              <tr>
                <td>{`${movingDate.year}年 ${movingDate.month}月 ${movingDate.date}日 ${movingDate.time < 12 ? "AM" : "PM"}`}</td>
                <td>仮申込み後、弊社オペレーター<br />よりご連絡致します。</td>
              </tr>
            </tbody>
          </table>
          <table className="table">
            <tbody>
              <tr>
                <td className="borderline basic-fee-cmt" colSpan={"2"}>基本料金</td>
              </tr>
              <tr>
                <td className="borderline">1トントラック</td>
                <td className="borderline">シングルプラン</td>
              </tr>
              <tr>
                <td className="borderline">2トントラック</td>
                <td className="borderline">スタンダードプラン</td>
              </tr>
              <tr>
                <td className="borderline">3トントラック</td>
                <td className="borderline">訪問見積り</td>
              </tr>
              <tr>
                <td className="borderline distance-fee-cmt" colSpan={"2"}>距離料金</td>
              </tr>
              <tr>
                <td className="borderline">〜20kmまで無料サービス</td>
                <td className="borderline">〜20kmから1kmあたりの距離料金</td>
              </tr>
            </tbody>
          </table>
          <table className=" total-fee-table">
            <tbody>
              <tr>
                <td className="borderline" colSpan={"2"}>{furniture.percentData2 < 100 ? <>お引越料金は <span className="total-fee-value">{totalFee || ''}</span>円 (税込)</> : <>2トン以上のお荷物は訪問見積もりになります。 公式サイトのお見積もりフォームよりご連絡ください。</>}</td>
              </tr>
              <tr>
                <td className="borderline">明細</td>
                <td className="borderline">料金</td>
              </tr>
              <tr>
                <td className="borderline basic-fee-title">基本料金</td>
                <td className="borderline">{furniture.percentData2 < 100 ? `${basicFee || ''}円` : '訪問見積もり'}</td>
              </tr>
              <tr>
                <td className="borderline distance-fee-title">距離料金</td>
                <td className="borderline">{furniture.percentData2 < 100 ? `${distanceFee || ''}円` : '訪問見積もり'}</td>
              </tr>
              <tr>
                <td className="borderline">段ボール料金</td>
                <td className="borderline">{furniture.percentData2 < 100 ? `${cardboardFee}円` : '訪問見積もり'}</td>
              </tr>
            </tbody>
          </table>
          <div className="tel-check-txt">※概算料金となります。搬入先や搬出先状況により金額の変動がございますのでお電話にて最終確認させて頂きます。</div>
        </div>
      </div>
      <div className="reconfirm-btn">
        <div className="btn-set">
          <Link to={"/movingdate"} className="btn-shape fur-btn-previous">戻る</Link>
          <Link to={"/"} className="btn-shape fur-btn-reset">最初からやり直す</Link>
        </div>
        <button onClick={reconfirmBtnclick} className="fur-btn-next">この料金で仮申し込みをする</button>
      </div>

    </section>
  )
}

export default Reconfirm