import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { addressReducer } from './address';
import { furnitureReducer } from './furniturelist';
import { movingDateReducer } from './movingDate';
import { contactUsReducer } from './contact';
import { feeReducer } from './fee';
import { reconfirmReducer } from './reconfirm';


const persistConfig = {
    key: 'quotation-management',
    storage,
    stateReconciler: autoMergeLevel1,
};
const reducers = combineReducers({
  address: addressReducer,
  furniture: furnitureReducer,
  movingDate: movingDateReducer,
  contactUs: contactUsReducer,
  fee: feeReducer,
  reconfirm: reconfirmReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
})
export const persistor = persistStore(store)