import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Address from "./component/address"
import Furniture from "./component/furniture"
import Confirm from "./component/confirm";
import MovingDate from "./component/movingdate";
import Reconfirm from "./component/reconfirm";
import Contact from "./component/contact";
import Inputcheck from "./component/input";
import Complete from "./component/complete";
import Footer from "./component/footer";


const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Address />} index />
          <Route path="/furniture" element={<Furniture />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/movingdate" element={<MovingDate />} />
          <Route path="/reconfirm" element={<Reconfirm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/input" element={<Inputcheck />} />
          <Route path="/complete" element={<Complete />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
