import React, { useState } from "react";


const CardboardItems = (props) => {
  const [selected, setSelected] = useState("")
  const handleChangeFurnitureNumber = (e) => {
    props.changeFurniture(e.target.value);
    e.target.value !== '0' ? setSelected("test") : setSelected("");    
  }

  return (
    <div className="furniture-items full-width">
      <div className="items-img" >
        <img className="cardboard" src={`https://hikkoshi-mitsumori.site/${props.image}`} alt="furniture-items" />
      </div>
      <div className={`items-label ${selected}  ${props.className}`}>{props.name}</div>
      <div className={`items-num ${selected}  ${props.className}`}>
        <select id="furniture-number" name="furniture-number" onChange={handleChangeFurnitureNumber} disabled={props.disabled}>
        {
           new Array(81).fill("").map(((e, i) => {
            return (
              <option value={i} selected={props.number === i}>{i}個</option>
              );
          }))
        }
        </select>
      </div>
    </div>          
  )
}          

export default CardboardItems