import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux"
import { set_building } from "../store/furniturelist";

const Building = () => {
  const dispatch = useDispatch();
  const furniture = useSelector(state => state.furniture);
  useEffect(() => {
    dispatch(set_building(1));
  }, [])
  const handleChange = (value) => {  
    dispatch(set_building(Number(value)));
  }

  return (
    <div className="build">
      <div className="build-txt">お引越し先の建物(必須項目)</div>
      <div>
        <select className="option" onChange={(e) => handleChange(e.target.value)}>
          <option value={1} selected={ 1 == furniture.building }>お越し先の建物を選択</option>
          <option value={2} selected={ 2 == furniture.building }>新築戸建</option>
          <option value={3} selected={ 3 == furniture.building }>新築マンション</option>
          <option value={4} selected={ 4 == furniture.building }>新築アパート</option>
          <option value={5} selected={ 5 == furniture.building }>築浅物件</option>
          <option value={6} selected={ 6 == furniture.building }>リフォーム物件</option>
        </select>
      </div>
    </div>
  )
}

export default Building;