import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_movingDate } from "../store/movingDate";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const DateModal = (props) => {
  let navigate = useNavigate();
  const [morningVisible, setMorningVisible] = useState(true);
  const [afternoonVisible, setAfternoonVisible] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const routeChange = () => {
    navigate("/reconfirm");
  }
  const { movingDate } = useSelector(state => state);
  const dispatch = useDispatch();
  const handleData = (state) => {
    const time = state;
    const newTime = {
      ...movingDate,
      time,
    }
    routeChange();
    dispatch(set_movingDate(newTime));
  }
  
  useEffect(()=>{
    setAfternoonVisible(true);
    setMorningVisible(true);
    setModalOpen(props.open);
    if(props.hours.length === 1) {
      const hour = Number(props.hours[0]);
      if(hour < 12) setMorningVisible(false);
      else setAfternoonVisible(false);
    }
  },[props]);
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className="modal-close" onClick={props.onClose}>X</button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`${movingDate.year}年 ${movingDate.month}月 ${movingDate.date}日`}
          </Typography>
          <div className="modal-btn">
            <button onClick={() => handleData(9)} className="date morning">午前</button>
            <button onClick={() => handleData(15)} className="date afternoon">午後</button>
          </div>
        </Box>
      </Modal>
    </>
  );
}