import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
  currentPrefecture: '',
  currentCity: '',
  newPrefecture: '',
  newCity: '',
  distance: ''
};

export const addressSlice = createSlice({    //update store
  name: "address",
  initialState: defaultState,
  reducers: {
    set_address: (state, action) => {
      state.currentPrefecture = action.payload.currentPrefecture;
      state.currentCity = action.payload.currentCity;
      state.newPrefecture = action.payload.newPrefecture;
      state.newCity = action.payload.newCity;
      state.distance = action.payload.distance;
    }
  }
})

export const { set_address } = addressSlice.actions;
export const addressReducer =  addressSlice.reducer