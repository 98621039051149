import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Prefecturelist } from "../values/value";
import { ConfirmItem } from "./items";
import Header from "./header";

const Confirm = () => {
  const address = useSelector(state => state.address);
  const currentPrefecture = Prefecturelist.filter((item) => (item.name === address.currentPrefecture))[0];
  const newPrefecture = Prefecturelist.filter((item) => (item.name === address.newPrefecture))[0];
  const selectedFurniture = useSelector((state) => (state.furniture));
  const { furniture } = useSelector(state => state);

  return (
    <section>
      <Header />
      <div className="percent-bar">
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData1} step={"10"} />
          <div className="number">{furniture.percentData1}%</div>
        </div>
        <div className="percent">
          <label>家財メーター</label>
          <input type="range" min={"0"} max={"100"} value={furniture.percentData2} step={"10"} />
          <div className="number">{furniture.percentData2}%</div>
        </div>
        {furniture.percentData2 && furniture.percentData2 === 100 && (
          <div className="visit-price">2トン以上のお荷物は訪問見積もりになります</div>
        )}
      </div>
      <div className="current-new-address">
        <div className="current">
          <p className="current-label">現住所</p>
          <div className="current-address-confir">{currentPrefecture.name + address.currentCity}</div>
        </div>
        <div className="movement-arrow">
          <img src="./image/arrow.png" alt="arrow" />
        </div>
        <div className="new">
          <p className="new-label">転居先</p>
          <div className="new-address-confir">{newPrefecture.name + address.newCity}</div>
        </div>
      </div>
      <div className="step1"><img src="./image/step1.png" alt="step1" /></div>
      <div className="furniture-list">
        <div className="furniture-list-title">選んだ家財</div>
        <div className="detail-list">
          {selectedFurniture.furnitureData.map((item, index) => (
            <ConfirmItem
              key={index}
              image={furniture.allFurnitures.find((value) => (item.furnitureId === value.id)).image}
              name={furniture.allFurnitures.find((furniture) => (item.furnitureId === furniture.id)).name}
              number={item.furnitureNumber}
              disabled={true}
            />
          ))}
        </div>
      </div>
      <div className="furniture-btn">
        <Link to={"/furniture"} className="btn-shape fur-btn-previous">戻る</Link>
        <Link to={"/movingdate"} className="fur-btn-next">予定日を選択</Link>
      </div>
    </section>
  )
}

export default Confirm