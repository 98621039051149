import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  furnitureData: [],
  allFurnitures: [],
  percentData1: 0,
  percentData2: 0,
  building: 0,
};

export const furnitureSlice = createSlice({
  name: "furniture",
  initialState: defaultState,
  reducers: {
    //store the selected furniture
    set_furniture: (state, action) => {
      state.furnitureData = action.payload;
    },
    set_percentData1: (state, action) => {
      state.percentData1 = action.payload;
    },
    set_percentData2: (state, action) => {
      state.percentData2 = action.payload;
    },
    delete_furniture: (state, action) => {
      state.furnitureData = [];
      state.percentData1 = 0;
      state.percentData2 = 0;
    },
    all_furnitures: (state, action) => {
      state.allFurnitures = action.payload;
    },
    set_building: (state, action) => {
      state.building = action.payload;
    },
  }
})

export const { set_furniture, set_percentData1, set_percentData2, delete_furniture, all_furnitures, set_building } = furnitureSlice.actions;
export const furnitureReducer = furnitureSlice.reducer