import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    ttlDistance: "",
};

export const reconfirmSlice = createSlice({
    name: 'reconfirm',
    initialState: defaultState,
    reducers: {
        set_reconfirm: (state, action) => {
            state.ttlDistance = action.payload;
        }
    }
})

export const { set_reconfirm } = reconfirmSlice.actions;
export const reconfirmReducer = reconfirmSlice.reducer